// проверяем ссылки-якори. Есть ли нужный блок на текущей странице
$('.js-scrollToElem, .js-scrollToElemForModal, .js-scrollToElemFromMenu').each(function () {
  const scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .

  if ($(scroll_el).length == 0 && scroll_el.includes('#') && !scroll_el.includes('http')) {
    $(this).attr('href', window.location.origin + $(this).attr('href'));
  }
});

$('.js-scrollToElem').click(function (event) { // ловим клик по ссылке с классом go_to
 const scrolled = scrollToElement(event, this);

  if ($(this).hasClass('js-questionlink')) {
    $('.js-selectShowAll').val(1).change();
  }

  return scrolled;
});

$(document).on('click', '.js-scrollToElemForModal', function (event) { // ловим клик по ссылке с классом go_to
  return scrollToElement(event, this, () => {
    $('.js-modalAnimateClose').trigger('click');
  });
});


$(document).on('click', '.js-scrollToElemFromMenu', function (event) { // ловим клик по ссылке с классом go_to
  return scrollToElement(event, this, () => {
    $('.js-openMenu').trigger('click');
  });
});

$(document).on('click', '.header__logo-link', function(event){ // ловим клик по ссылке с классом go_to
  if (location.pathname === '/') {
    event.preventDefault();
    animateScroll('html, body');
    return false; // выключаем стандартное действие
  }
});

const scrollToElement = (e, el, preScrollActionCallback) => {
  const scroll_el = $(el).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .

  if (preScrollActionCallback) {
    preScrollActionCallback();
  }

  if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
    e.preventDefault();

    setTimeout(() => {
      animateScroll(scroll_el);
    }, preScrollActionCallback ? 100 : 0)

    return false
  }

  return true
}

const animateScroll = (scroll_el) => {
  const header = $('.header');
  $('html, body').animate({scrollTop: $(scroll_el).offset().top - $(header).innerHeight()}, 500); // анимируем скроолинг к элементу scroll_el
}
