// import Swiper from 'swiper/bundle';
// import Swiper from '../../../node_modules/swiper/swiper-bundle.min.js';

$(document).on("sliderDetail", function () {
  if ($('.js-detailSlider').length > 0) {
    let sliderDetail = new Swiper('.js-detailSlider', {
      slidesPerView: 1,
      speed: 300,
      // effect: 'fade',
      // autoHeight: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: ".js-detailSliderNextBtn",
        prevEl: ".js-detailSliderPrevBtn",
      },
      pagination: {
        el: '.js-detailSliderPagination',
        type: 'bullets',
        clickable: true
      },
    });
  }
});

$(document).trigger('sliderDetail');
