$(document).on("change input", ".form-input", (event) => {
	inputValueChecking($(event.currentTarget));
});


$(document).on("focus", ".form-input", (event) => {
	// console.log('focus = .form-input');
	const input = $(event.currentTarget);
	const fieldsetLine = input.parents(".fieldset-line");

	input.parent().addClass("is-focus");
	fieldsetLine.addClass("is-focus-line");
});

$(document).on("blur", ".form-input", (event) => {
	// console.log('blur = .form-input');
	const input = $(event.currentTarget);
	const fieldsetLine = input.parents(".fieldset-line");
	fieldsetLine.removeClass("is-focus-line");
	// inputValueChecking($(event.currentTarget));
	setTimeout(() => {
		inputValueChecking($(event.currentTarget));
	}, 100);
});

formInputCheck();

$(window).on("load", () => {
	formInputCheck();
});

function formInputCheck() {
	$(".form-input").each((index, element) => {
		inputValueChecking($(element));
	});
}

function inputValueChecking(input) {
	// (input.val() != '' &&  input.val() != null) ? input.parent().addClass('is-focus') : input.parent().removeClass('is-focus');
	if ((input.val() != "" && input.val() != null) || input.is(":focus")) {
		input.parent().addClass("is-focus");
	} else {
		input.parent().removeClass("is-focus");
	}
}



// document.addEventListener('DOMContentLoaded', function(){
// 	console.log('selectInit');
// 	selectInit();
// });
// $(window).on("load", function () {
// 	selectInit();
// });


$(document).on("click", ".js-modal-ok-close", function() {
  $(this).closest('.modal-ok').removeClass('modal-ok--show');
});


//select2
//select2
let selectInit = function () {
	if (
		$(".js-select2Single").length &&
		!("ontouchstart" in window || navigator.maxTouchPoints > 1)
	) {
		$(".js-select2Single").each(function () {
			let selectItem = $(this).select2({
				// theme: "default form-select2",
				theme: "default form-select2",
				minimumResultsForSearch: 100000,
				placeholder: $(this).data("placeholder"),
				width: "100%",
				language: {
					noResults: function () {
						return $(this).data("noresult-title");
					},
				},
			});

			if ($(this).parents(".js-validate").length > 0) {
				$(this).on("select2:select", function (event) {
					// console.log('select2:select');
					$(event.currentTarget).valid();
				});
				// $(this).on("select2:open", function (event) {
				// 	const input2 = $(event.currentTarget);
				// 	const fieldsetLine2 = input2.parents(".fieldset-line");

				// 	input2.parent().addClass("is-focus");
				// 	fieldsetLine2.addClass("is-focus-line");
				// });
				$(this).on("select2:open", function (event) {
					const input2 = $(event.currentTarget);
					const fieldsetLine2 = input2.parents(".fieldset-line");

					input2.parent().addClass("is-focus");
					fieldsetLine2.addClass("is-focus-line");
					input2.parent().addClass("is-focus-select");
				});
				// $(this).on('select2:change', function(event) {
				// 	console.log('select2:change');
				// });
				$(this).on("change", function (event) {
					// console.log('select2:open');

					const input2 = $(event.currentTarget);
					const fieldsetLine2 = input2.parents(".fieldset-line");

					input2.parent().addClass("is-focus");
					fieldsetLine2.addClass("is-focus-line");
					// console.log('select2 = change');
				});
				$(this).on("select2:close", function (event) {
					// console.log('select2:close');

					const input3 = $(event.currentTarget);
					const fieldsetLine3 = input3.parents(".fieldset-line");
					fieldsetLine3.removeClass("is-focus-line");
					setTimeout(() => {
						inputValueChecking($(event.currentTarget));
					}, 100);
				});
			}
		});
	}
};

document.addEventListener('DOMContentLoaded', function(){
	selectInit();
});
$(window).on("load", function () {
	selectInit();
});

//select2 END
//select2 END
