import magnificPopup from 'magnific-popup';

const body = $('body');

function modalOpen(src, type){
    $.magnificPopup.open({
        tLoading: 'Загрузка...',
        items: {
            src: src,
            type: type
        },
        closeBtnInside: true,

        callbacks: {
            beforeOpen: function() {
                $('html').addClass('mfp-open');
            },

            beforeClose: function() {
                if($('.mfp-wrap').find('video').length > 0){
                    $('.mfp-wrap').find('video')[0].pause();
                }
            },

        }
    });
}

$(document).on('click', '.js-modalLink', function(event) {
    const src = $(this).data('mfp-src');
    const type = $(this).data('mfp-ajax') || 'inline';

    event.preventDefault();

    modalOpen(src, type);
});

$(document).on('click', '.js-modalClose', (event) => {
    event.preventDefault();
    $.magnificPopup.close();
});

// new

function modalAnimateOpen(src, type){
    $.magnificPopup.open({
        tLoading: 'Загрузка...',
        items: {
            src: src,
            type: type
        },
        closeBtnInside: true,
        removalDelay: 300,
        callbacks: {
            beforeOpen: function() {
                $('html').addClass('mfp-open');
                // this.st.mainClass = this.st.el.attr('data-effect');
                this.st.mainClass = 'modal-move-effect';
                // console.log(this.st);
            },
            ajaxContentAdded: function() {
                $('html').addClass('mfp-open-ajaxContentAdded');
                // console.log('mfp-open-ajax-add-content');
                $(document).trigger('sliderDetail');
            },

            beforeClose: function() {
                if($('.mfp-wrap').find('video').length > 0){
                    $('.mfp-wrap').find('video')[0].pause();
                }
                $('.mfp-wrap.modal-move-effect').removeClass('mfp-ready');
                // $('html').removeClass('mfp-open-ajaxContentAdded');

                // console.log('mfp-beforeClose');
            },
            close: function() {
                $('html').removeClass('mfp-open-ajaxContentAdded');
                // console.log('mfp-close');
            },

        }
    });
}


$(document).on('click', '.js-modalAnimateClose', (event) => {
    event.preventDefault();
    $.magnificPopup.close();
});


$(document).on('click', '.js-modalAnimateLink', function(event) {
    event.preventDefault();
    const src = $(this).data('mfp-src');
    const type = $(this).data('mfp-ajax') || 'inline';

    event.preventDefault();

    // console.log($.magnificPopup);
    // $.magnificPopup.close();
    modalAnimateOpen(src, type);
});


$(document).on('click', '.js-modalAnimateLink2', function(event) {
    const src = $(this).data('mfp-src');
    const type = $(this).data('mfp-ajax') || 'inline';

    event.preventDefault();

    // console.log($.magnificPopup);
    modalAnimateOpen(src, type);
    $('.modal-move-effect').addClass('effect_hide');
    setTimeout(function() {
        $('.modal-move-effect').removeClass('effect_hide');
    }, 200)
});
